// @ts-nocheck
import { breakpoints, useHooks } from '@everlywell/leaves';
import React, { useState } from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { MEMBERSHIP_CARD } from 'utils/constants/dataTest';
import { APP_ROOT } from 'utils/constants/urls';
import { redirectToPDP } from 'utils/helpers/products';
import CheckedItem from '../../CheckedItem';
import Collapse from '../../Collapse/index';
import { CollectionType } from '../../CollectionType';
import * as S from './styles';
import useOutOfStockSlugs from './useOutOfStockSlugs';

type Marker = {
  title: string;
};

type Symptom = {
  title: string;
};

export type ProductSelectItem = {
  label: string;
  value: string;
  originalPrice: string;
  additionalInfo: string;
  pdpSlug: string;
  boxImage: string;
  category: string;
  markers?: Marker[];
  symptoms?: Symptom[];
  collectionMethods: CollectionType[];
  shortDescription: string;
};

type Props = {
  product: Product;
  selectedValue: string;
  onChange: (x: string) => void;
  isAuthorized: boolean;
};

const ProductSelectItem = ({
  product,
  selectedValue,
  onChange,
  isAuthorized,
}: Props): JSX.Element => {
  const [_expandSymptoms, _setExpandSymptoms] = useState(false);
  const { label, value, originalPrice, additionalInfo, pdpSlug, boxImage } =
    product;

  const windowSize = useHooks.useWindowSize();
  const isTabletUp = windowSize?.width
    ? windowSize.width >= breakpoints.forTabletHorizontalUp
    : false;

  const { isOutOfStock } = useOutOfStockSlugs();

  const disabled = isOutOfStock(pdpSlug);

  const handleClick = (): void => {
    ewAnalytics.track({
      data: {
        SKU: product.pdpSlug,
      },
      event: ANALYTICS.EVENTS.MEMBERSHIP_CLICKED_BUTTON,
    });
  };

  const handleChange = (e: Event): void => {
    onChange(e.currentTarget.value);
  };

  const trackEvent = (event: string, label: string) => {
    ewAnalytics.track({
      event,
      data: {
        label,
        productSlug: product.pdpSlug,
      },
    });
  };

  const onToggleProductDetails = (expanded: boolean) => {
    trackEvent(
      ANALYTICS.EVENTS.CLICKED_BUTTON,
      expanded
        ? ANALYTICS.LABELS.PRODUCT_TILE_EXPAND
        : ANALYTICS.LABELS.PRODUCT_TILE_COLLAPSE,
    );
  };

  const onExpandSymptoms = () => {
    const expandSymptomsState = !_expandSymptoms;
    _setExpandSymptoms(expandSymptomsState);
    trackEvent(
      ANALYTICS.EVENTS.CLICKED_BUTTON,
      expandSymptomsState
        ? ANALYTICS.LABELS.PRODUCT_TILE_SEE_MORE
        : ANALYTICS.LABELS.PRODUCT_TILE_SEE_LESS,
    );
  };

  const header = (
    <S.Wrapper isAuthorized={isAuthorized}>
      <S.Input
        type="radio"
        id={label}
        value={value}
        checked={selectedValue === value}
        onChange={handleChange}
        disabled={disabled}
        data-testid={MEMBERSHIP_CARD.SELECT_INPUT}
      />
      {/*
      // @ts-ignore */}
      <S.Label hasCredits={!disabled} htmlFor={label}>
        <S.LabelInner>
          <S.ImageWrapper>
            <S.CircleBackground />
            <S.BoxImage
              src={boxImage}
              onClick={() => redirectToPDP(pdpSlug)}
              alt={`${label} kit`}
            />
          </S.ImageWrapper>
          <S.TextContent isAuthorized={isAuthorized}>
            <S.ProductName
              target={isTabletUp ? '_blank' : null}
              rel="noopener noreferrer"
              href={`${APP_ROOT}/products/${pdpSlug}`}
              onClick={handleClick}
            >
              {label}
            </S.ProductName>
            {!isAuthorized && (
              <S.ProductOriginalPrice>
                {isOutOfStock(pdpSlug)
                  ? `Currently Unavailable`
                  : `(Regular Price ${originalPrice})`}
              </S.ProductOriginalPrice>
            )}
          </S.TextContent>
        </S.LabelInner>
        {additionalInfo ? (
          <S.AdditionalInfo>{additionalInfo}</S.AdditionalInfo>
        ) : null}
      </S.Label>
    </S.Wrapper>
  );

  const hasBiomarkers =
    product.markers &&
    Array.isArray(product.markers) &&
    !!product.markers.length;
  const hasSymptoms =
    product.symptoms &&
    Array.isArray(product.symptoms) &&
    !!product.symptoms.length;
  const hasCollectionMethods =
    product.collectionMethods &&
    Array.isArray(product.collectionMethods) &&
    !!product.collectionMethods.length;

  return (
    <Collapse header={header} onOpened={onToggleProductDetails} noMargin>
      <S.InnerContainer>
        <S.Content>{product.shortDescription}</S.Content>
        {hasBiomarkers && (
          <>
            <S.Title>Biomarkers:</S.Title>
            {product.markers?.map((marker, index) => (
              <CheckedItem key={`${marker.id}${index}`} title={marker.title} />
            ))}
          </>
        )}
        {hasSymptoms && (
          <>
            <S.Title>Symptoms:</S.Title>
            <S.UL>
              {product.symptoms.map((symptom, index) => (
                <S.LI
                  key={`${symptom.id}${index}`}
                  visible={_expandSymptoms || index < 3}
                >
                  {symptom.title}
                </S.LI>
              ))}
            </S.UL>
            <S.Link onClick={onExpandSymptoms}>
              {_expandSymptoms ? 'See less' : 'See more'}
            </S.Link>
          </>
        )}
        {hasCollectionMethods && (
          <>
            {product.collectionMethods.map((collection) => (
              <CollectionType key={collection.id} {...collection} />
            ))}
          </>
        )}
      </S.InnerContainer>
    </Collapse>
  );
};

export default ProductSelectItem;
