import React, { useState } from 'react';

import * as S from './styles';

type Props = {
  onOpened?: (opened: boolean) => void;
  header: React.ReactChild;
  children: React.ReactChild;
  noMargin: boolean;
};

const Collapse = ({
  children,
  header,
  onOpened,
  noMargin,
}: Props): JSX.Element => {
  const [_open, _setOpen] = useState(false);

  const onButtonClick = () => {
    const opened = !_open;
    _setOpen(opened);
    onOpened && onOpened(opened);
  };

  return (
    <S.Wrapper isOpen={_open} noMargin={noMargin}>
      <S.Header isOpen={_open}>
        {header}{' '}
        <S.Button
          onClick={onButtonClick}
          aria-label="Collapse button"
          title="Collapse button"
        >
          <S.Icon isOpen={_open} />
        </S.Button>
      </S.Header>
      <S.Body isOpen={_open}>{children}</S.Body>
    </S.Wrapper>
  );
};

export default Collapse;
