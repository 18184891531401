import { graphql, useStaticQuery } from 'gatsby';
import { getSkuKey } from 'utils/helpers';
import { getVariantPrice } from 'utils/helpers/membershipSkuHelper';
import { Variant } from 'utils/types';

import { categoryProductSKUMap } from './membershipProductSkuMap';

type ProductSelectItem = {
  label: string;
  value: string;
  originalPrice: string;
  additionalInfo: string;
  pdpSlug: string;
  boxImage: string;
  category: string;
};

export const useProductsByCategory = () => {
  const { allSpreeProduct } = useStaticQuery(graphql`
    query {
      allSpreeProduct {
        nodes {
          variants {
            price
            price_in_dollars
            sku
          }
        }
      }
    }
  `);

  const getProductsByCategory = (category: string, product: any) => {
    const skuKey = getSkuKey(product) ?? '';
    const productSKUMap = categoryProductSKUMap[skuKey][category]?.items ?? {};

    // gets the available kit options
    const getFreeKitOptions = (variants: Variant[]): ProductSelectItem[] =>
      variants
        .filter((v) => {
          const skuKey = getSkuKey(v, true) ?? '';
          return (
            !v.is_master &&
            !v.subscription_plan &&
            (productSKUMap[skuKey] ?? false)
          );
        })
        .map((variant) => {
          const skuKey = getSkuKey(variant, true) ?? '';
          const productData = productSKUMap[skuKey];
          const price = getVariantPrice(allSpreeProduct.nodes, skuKey);
          return {
            label: productData.title,
            value: `${variant.id}`,
            originalPrice: price || productData.originalPrice,
            additionalInfo: productData.additionalInfo,
            pdpSlug: productData.pdpSlug,
            boxImage: productData.boxImage,
            category: productData.category,
            // @ts-ignore
            markers: variant.membershipProductInfo?.markerList?.markers || [],
            symptoms:
              variant.membershipProductInfo?.symptomList?.symptoms || [],
            collectionMethods:
              variant.membershipProductInfo?.collectionMethods || [],
            shortDescription: variant.membershipProductInfo?.shortDescription,
          };
        });

    const { variants } = product;
    return getFreeKitOptions(variants);
  };

  return {
    getProductsByCategory,
  };
};

export default useProductsByCategory;
