import React from 'react';

import * as S from './styles';

type Props = {
  title: string;
};

const CheckedItem = ({ title }: Props) => (
  <S.Wrapper>
    <S.Icon />
    <S.Title>{title}</S.Title>
  </S.Wrapper>
);

export default CheckedItem;
