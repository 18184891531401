/* stylelint-disable property-no-vendor-prefix */
import { Col, size, Icons, colors } from '@everlywell/leaves';
import styled from 'styled-components';

const maxHeight = '90px';
const paddingContainers = `0 ${size.lg}px 0 10px`;
const paddingContainersOpened = `0 ${size.xl3}px 0 26px`;

interface OpenProp {
  isOpen: boolean;
}
interface WrapperProps {
  isOpen: boolean;
  noMargin: boolean;
}
const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  margin-bottom: ${(noMargin) => (noMargin ? 0 : size.lg)}px;
  border-radius: 3px;
  box-shadow: 0 ${size.xs3}px ${size.md}px 0
    rgba(${(props) => props.theme.black}, 0.1);
  position: relative;
  background: ${colors.white};
  display: block;
  padding: ${(props) => (props.isOpen ? `20px 0` : `20px ${size.md}px;`)};
  height: ${(props) => (props.isOpen ? 'auto' : `${maxHeight};`)};
  transition: opacity 0.5s ease;
  overflow: hidden;
`;
const Header = styled.div<OpenProp>`
  width: 100%;
  position: static;
  display: flex;
  padding: ${(props) =>
    props.isOpen ? paddingContainersOpened : paddingContainers};
`;

const Button = styled.button`
  width: ${size.xl3}px;
  height: ${maxHeight};
  top: 0;
  right: 0;
  cursor: pointer;
  position: absolute;
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Body = styled(Col)<OpenProp>`
  width: 100%;
  height: ${(props) => (props.isOpen ? 'auto' : '0')};
  overflow: hidden;
  margin-top: ${(props) => (props.isOpen ? size.lg : '0')}px;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transition: opacity 0.7s ease;
  padding: ${(props) =>
    props.isOpen ? paddingContainersOpened : paddingContainers};
`;

const Icon = styled(Icons.UpCaret)<OpenProp>`
  width: ${size.md}px;
  cursor: pointer;
  transform: rotate(${(props) => (props.isOpen ? '0deg' : '180deg')});
  color: ${colors.green5};
  transition: all 0.5s ease;
`;

export { Wrapper, Header, Body, Button, Icon };
