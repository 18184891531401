import { useStaticQuery, graphql } from 'gatsby';

const useOutOfStockSlugs = () => {
  const result = useStaticQuery(graphql`
    query outOfStock {
      outOfStockSlugs: allContentfulProduct(
        filter: { outOfStock: { eq: true } }
      ) {
        nodes {
          slug
        }
      }
    }
  `);

  const slugs = new Set<string>(
    result.outOfStockSlugs.nodes.map((node: { slug: string }) => node.slug),
  );
  const isOutOfStock = (slug: string) => slugs.has(slug);

  return {
    outOfStockSlugs: slugs,
    isOutOfStock,
  };
};

export default useOutOfStockSlugs;
