import TestsIncludedSection from 'components/MembershipPDP/TestsIncludedSection';
import React from 'react';
import { PageSectionType, Product } from 'utils/types';

export type TestsIncludedContainerProps = {
  product: Product;
  content: PageSectionType;
  isMember: boolean;
};

export const TestsIncludedContainer: React.FC<TestsIncludedContainerProps> = ({
  product,
  content,
  isMember,
}: TestsIncludedContainerProps) => {
  if (!content) return null;

  const { slug, title, description, headline } = content;

  return (
    <TestsIncludedSection
      uid={slug}
      title={title}
      description={description?.description}
      headline={headline}
      product={product}
      isMember={isMember}
    />
  );
};

export default TestsIncludedContainer;
