import { typography, size, mediaQueries } from '@everlywell/leaves';

import { AddToCart } from 'components/ProductCard/styles';
import styled from 'styled-components';

type ProductSelectWrapperProps = {
  singleCategory: boolean;
};
const ProductSelectWrapper = styled.div`
  display: grid;
  width: 100%;
  padding: ${size.sm}px 0;
  box-sizing: border-box;
  grid-column: 1;
  grid-template-columns: 1fr;
  grid-gap: 20px;

  ${mediaQueries.forTabletVerticalUp} {
    grid-column: ${(props: ProductSelectWrapperProps) =>
      props.singleCategory ? 2 : 1};
    grid-template-columns: ${(props: ProductSelectWrapperProps) =>
      props.singleCategory ? '1fr 1fr' : '1fr'};
  }

  ${mediaQueries.forDesktopUp} {
    grid-column: ${(props: ProductSelectWrapperProps) =>
      props.singleCategory ? 3 : 1};
    grid-template-columns: ${(props: ProductSelectWrapperProps) =>
      props.singleCategory ? '1fr 1fr 1fr' : '1fr'};
  }

  ul,
  li {
    list-style-type: none;
  }
`;

// want to use DLS button here - but the implementation for adding a product to the cart
// requires that we pass a ref to the button.  The button in DLS needs to be updated to add
// a ref if passed one - waiting until DLS is up to date with new cart stuff to make this change.
// until then, gonna use the AddToCart button that is defined in the ProductCart component
const OrderTestButton = styled(AddToCart)`
  width: 100%;
  max-width: 230px;
  text-align: center;
  text-transform: none;
  font-size: 18.7px;
  padding: 10px 21px;
  margin: 0 auto;

  &[disabled] {
    color: rgb(${(props) => props.theme.colors.white});
    border: none;
    background-color: rgba(${(props) => props.theme.colors.silverChalice}, 0.9);
  }

  &[disabled]::after {
    animation: unset;
    content: unset;
  }
`;

const Policy = styled.div`
  margin-top: ${size.xl4}px;
`;

const PolicyTitle = styled.div`
  ${typography.h5Text};
  margin-bottom: 8px;
  text-align: left;
`;

const PolicyText = styled.div`
  ${typography.bodyText};
  text-align: left;

  a {
    text-decoration: none;
    ${typography.buttonText};
  }
`;

export const Category = styled.h5`
  ${typography.h5Text};
  width: 100%;
  text-align: left;
  margin-bottom: ${size.md}px;
`;

export const ListWrapper = styled.div`
  padding-top: ${size.xl1}px;

  ${mediaQueries.forTabletHorizontalUp} {
    display: flex;
  }
`;

export const ProductSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${mediaQueries.forTabletVerticalUp} {
    align-items: center;
  }
`;

export const InnerContent = styled.div`
  padding-top: ${size.xl4}px;
`;

export {
  ProductSelectWrapper,
  OrderTestButton,
  Policy,
  PolicyTitle,
  PolicyText,
};
