/* stylelint-disable property-no-vendor-prefix */
import { size, Row, Icons, colors } from '@everlywell/leaves';
import styled from 'styled-components';

const Wrapper = styled(Row)`
  width: 100%;
  display: flex;
  margin: 0;
`;

const Icon = styled(Icons.Success)`
  width: ${size.sm}px;
  color: ${colors.green5};
  margin-top: 3px;
  margin-right: 5px;
`;

const Title = styled.div`
  color: ${colors.gray4};
  font-weight: normal;
`;

export { Wrapper, Icon, Title };
