import { breakpoints } from '@everlywell/leaves';
import { APP_ROOT } from 'utils/constants/urls';

export const redirectToPDP = (slug: string): void => {
  const viewportWidth = window.innerWidth;
  const isTabletUp = viewportWidth
    ? viewportWidth >= breakpoints.forTabletHorizontalUp
    : false;
  const url = `${APP_ROOT}/products/${slug}`;
  if (isTabletUp) {
    window.open(url, '_blank');
  } else {
    window.location.assign(url);
  }
};
