import { typography, mediaQueries, size, H4 } from '@everlywell/leaves';
import styled from 'styled-components';

export const SectionName = styled.div`
  ${typography.overlineText};
  text-align: center;
  margin: ${size.xl7}px 0 ${size.sm}px;

  ${mediaQueries.forTabletVerticalUp} {
    margin-top: ${size.xl4}px;
  }

  ${mediaQueries.forPhoneOnly} {
    margin-top: ${size.xl1}px;
  }
`;

export const SectionTitle = styled.div`
  ${typography.h2Text};
  text-align: center;
  margin-bottom: ${size.sm}px;

  ${mediaQueries.forTabletVerticalUp} {
    ${typography.h3Text};
  }
`;

export const SectionDescription = styled.div`
  ${typography.bodyText};
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
`;

export const TestsIncludedSection = styled.div`
  display: block;
`;

export const ProductSelectWrapper = styled.div`
  margin-top: ${size.xl1}px;
  overflow: hidden;
`;

export const InnerContent = styled.div`
  padding-top: ${size.lg}px;

  ${mediaQueries.forPhoneOnly} {
    padding-top: ${size.xs1}px;
  }
`;

export const SubHeadline = styled(H4).attrs((props) => ({
  id: props.id,
}))`
  text-align: center;
  ${typography.bodyText}
  margin: ${size.sm}px 0 ${size.md}px 0;
`;
