// @ts-nocheck
import { Container, Row, Col } from '@everlywell/leaves';
import ProductsByCategory from 'components/ProductsByCategory';
import React from 'react';
import { useProductsByCategory } from 'utils/hooks/useProductsByCategory';

import { getSkuKey } from '../../utils/helpers';
import { categoryProductSKUMap } from '../../utils/hooks/useProductsByCategory/membershipProductSkuMap';
import * as S from './styles';

type Props = {
  product: Product;
  isMember: boolean;
  onOptionChange: (x: string) => void;
  selectedValue: string;
};

type ProductSelectDetailProps = {
  RowComponent: JSX.Element;
  ColComponent: JSX.Element;
  categories: string[];
  props: Props;
};

const ProductSelectDetailForm = ({
  RowComponent,
  ColComponent,
  categories,
  props,
}: ProductSelectDetailProps): JSX.Element => {
  const { product, onOptionChange, selectedValue } = props;
  return (
    <RowComponent singleCategory={categories.length === 1}>
      {categories.map((category) => (
        <ColComponent
          md={categories.length === 1 ? 12 : 6}
          lg={categories.length === 1 ? 12 : 4}
          key={category}
        >
          <S.ProductSectionWrapper>
            <S.ProductSelectWrapper>
              <ProductsByCategory
                category={category}
                product={product}
                onOptionChange={onOptionChange}
                selectedValue={selectedValue}
                singleCategory={categories.length === 1}
              />
            </S.ProductSelectWrapper>
          </S.ProductSectionWrapper>
        </ColComponent>
      ))}
    </RowComponent>
  );
};

const ProductSelectForm = (props: Props): JSX.Element => {
  const { product } = props;
  const { getProductsByCategory } = useProductsByCategory();

  const categoryMap = categoryProductSKUMap[getSkuKey(product)] ?? [];
  const categoriesList = Object.keys(categoryMap) ?? [];
  const categories = categoriesList.filter(
    (category) => getProductsByCategory(category, product)?.length > 0,
  );

  if (!categories) return null;
  const RowComponent = categories.length > 3 ? S.ProductGridRow : Row;
  const ColComponent = categories.length > 3 ? S.ProductGridCol : Col;

  return (
    <Container>
      <ProductSelectDetailForm
        RowComponent={RowComponent}
        ColComponent={ColComponent}
        categories={categories}
        props={props}
      />
    </Container>
  );
};

export default ProductSelectForm;
