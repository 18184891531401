import React, { useContext } from 'react';
import { useProductsByCategory } from 'utils/hooks/useProductsByCategory';
import { UserContext } from 'utils/hooks/useUserContext/context';
import { Product } from 'utils/types';
import { getSkuKey } from 'utils/helpers';
import ProductSelectItem from '../ProductSelectForm/ProductSelectItem';
import { categoryProductSKUMap } from '../../utils/hooks/useProductsByCategory/membershipProductSkuMap';

import * as S from './styles';

type Props = {
  category: string;
  product: Product;
  onOptionChange: (x: string) => void;
  selectedValue: string;
  singleCategory: boolean;
};

const ProductsByCategory = ({
  category,
  onOptionChange,
  selectedValue,
  product,
  singleCategory,
}: Props): JSX.Element => {
  const skuKey = getSkuKey(product) ?? '';
  const productSKUMap = skuKey ? categoryProductSKUMap[skuKey] : {};
  const categoryLabel =
    productSKUMap && category ? productSKUMap[category]?.label : '';
  const {
    userData: { isMember, membershipType },
  } = useContext(UserContext);

  const { getProductsByCategory } = useProductsByCategory();

  const products = getProductsByCategory(category, product);

  const isAuthorized = Boolean(
    isMember && membershipType && product.sku.includes(membershipType),
  );

  return (
    <S.ProductSectionWrapper>
      <S.ProductSelectWrapper singleCategory={singleCategory}>
        {!singleCategory && category ? (
          <S.Category>{categoryLabel}</S.Category>
        ) : null}
        {products?.map((prod, index) => (
          <>
            <ProductSelectItem
              key={`${prod.value}${index}`}
              product={prod}
              selectedValue={selectedValue}
              onChange={onOptionChange}
              isAuthorized={isAuthorized}
            />
          </>
        ))}
      </S.ProductSelectWrapper>
    </S.ProductSectionWrapper>
  );
};

export default ProductsByCategory;
