import { typography } from '@everlywell/leaves';
import styled, { css } from 'styled-components';
import { linkPseudoStates } from 'styles/utils';

const Measures = styled.div`
  font-size: 14.2px;
  line-height: 1.97;
  text-align: left;
  color: rgb(${(props) => props.theme.colors.tundora});
  text-decoration: underline;
  margin-bottom: 24px;
  cursor: pointer;
`;

const MeasuresLabel = styled.div`
  font-size: 12px;
  letter-spacing: 0.3px;
  color: rgb(${(props) => props.theme.quaternaryTextColor});
  text-transform: uppercase;
  margin: 16px 0 8px;
  align-self: flex-start;
`;

const MeasuredTitle = styled.p`
  font-weight: ${typography.weight.regular};
  font-size: 20.3px;
  line-height: 1.58;
  color: rgb(${(props) => props.theme.primaryTextColor});
`;

const MeasuredMarkerContent = styled.span`
  font-size: 18px;
  font-weight: ${typography.weight.light};
  line-height: 1.78;
  color: rgb(${(props) => props.theme.secondaryTextColor});
`;

const MeasuredContentWrapper = styled.div`
  margin: 8px 0;
`;

const MeasuredMarkerTitle = styled(MeasuredMarkerContent)`
  font-weight: ${typography.weight.bold};
`;

const ProductTitle = styled.div`
  font-weight: ${typography.weight.bold};
  font-size: 18px;
  align-self: flex-start;
  margin: 8px 0;
  text-align: left;
`;

const ProductDescription = styled.div`
  font-weight: ${typography.weight.light};
  font-size: 14.2px;
  line-height: 1.69;
  text-align: left;
  color: ${(props) => props.theme.primaryTextColor};
`;

const ProductImage = styled.div`
  width: 210px;
  margin: 8px 0 17px;
  align-self: center;
`;

const ProductCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 2px;
  box-shadow: 0 2px 16px 0 rgba(${(props) => props.theme.black}, 0.05);
  background-color: #fff;
  padding: 0 16px;
  box-sizing: border-box;
  height: 100%;

  ${(mediaQuery) => mediaQuery.theme.forTabletHorizontalUp} {
    padding: 0 24px;
  }
`;

const ProductPrice = styled.div`
  font-size: 20.3px;
  line-height: 1.58;
  color: rgb(${(props) => props.theme.secondaryTextColor});
  font-weight: ${typography.weight.bold};
`;

const ProductTitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const CTAs = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  align-self: center;
  padding: 0 24px;
  bottom: 32px;
  box-sizing: border-box;

  ${(mediaQuery) => mediaQuery.theme.forPhoneOnly} {
    justify-content: center;
    flex-direction: column;
    bottom: 36px;
  }
`;

const LearnMore = styled.a`
  font-size: 16px;
  letter-spacing: 0.4px;
  color: rgb(${(props) => props.theme.tertiaryTextColor});
  cursor: pointer;
  transition: color 150ms ease-in;
  text-decoration: none;
  ${linkPseudoStates}
  ${(mediaQuery) => mediaQuery.theme.forPhoneOnly} {
    order: 1;
    margin-top: 24px;
  }
`;

export const DeprecatedButtonStyles = css`
  align-self: center;
  background-color: rgb(${(props) => props.theme.primaryBgColor});
  border-radius: 23.5px;
  color: rgb(${(props) => props.theme.white});
  cursor: pointer;
  font-weight: ${typography.weight.bold};
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 1px;
  padding: 12px 24px;
  text-transform: uppercase;
  transition: background-color 150ms ease-in;

  &:hover {
    background-color: rgb(${(props) => props.theme.primaryHoverBgColor});
    transition: background-color 150ms ease-in;
  }

  &[disabled] {
    background-color: rgba(${(props) => props.theme.white}, 0.9);
    border: 0.1rem solid rgb(${(props) => props.theme.primaryBgColor});
    color: transparent;
    cursor: wait;
    position: relative;
  }

  @keyframes loading {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &[disabled]::after {
    animation: 0.5s infinite linear loading;
    border: 0.1rem solid rgb(${(props) => props.theme.primaryBgColor});
    border-radius: 50%;
    border-right-color: transparent;
    border-top-color: transparent;
    content: '';
    display: block;
    height: 0.8rem;
    left: 50%;
    margin-left: -0.4rem;
    margin-top: -0.4rem;
    position: absolute;
    top: 50%;
    width: 0.8rem;
    z-index: 1;
  }
`;
const AddToCart = styled.div`
  ${DeprecatedButtonStyles}
`;

export {
  AddToCart,
  CTAs,
  LearnMore,
  MeasuredContentWrapper,
  MeasuredMarkerContent,
  MeasuredMarkerTitle,
  MeasuredTitle,
  Measures,
  MeasuresLabel,
  ProductTitle,
  ProductPrice,
  ProductTitleSection,
  ProductDescription,
  ProductImage,
  ProductCard,
};
