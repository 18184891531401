import React from 'react';

import * as S from './styles';

export type CollectionIconFile = {
  fileName: string;
  url: string;
};

export type CollectionIcon = {
  file: { url: string };
};

export interface CollectionType {
  icon: CollectionIcon;
  id: string;
  name: string;
}

export const CollectionType = ({ icon: { file }, name }: CollectionType) => (
  <S.Wrapper>
    {file && (
      <S.Icon>
        <img src={file.url} alt={`${name} icon`} />
      </S.Icon>
    )}
    <S.Title>{name || ''}</S.Title>
  </S.Wrapper>
);
