/* stylelint-disable property-no-vendor-prefix */
import { size, Row, colors } from '@everlywell/leaves';
import styled from 'styled-components';

const Wrapper = styled(Row)`
  width: 100%;
  display: flex;
  align-items: center;
  margin: ${size.sm}px 0 0 0;
`;

const Icon = styled.div`
  width: ${size.lg}px;
  color: ${colors.green5};
  margin-bottom: 3px;
  margin-right: ${size.xs1}px;
`;

const Title = styled.div`
  color: ${colors.gray4};
  font-weight: 500;
`;

export { Wrapper, Icon, Title };
