import {
  typography,
  size,
  colors,
  mediaQueries,
  TertiaryLink,
} from '@everlywell/leaves';
import styled, { css } from 'styled-components';

import { ariesTheme } from '../../../styles/ariesTheme';

const Container = styled.div`
  width: 100%;
`;

const InnerContainer = styled.div`
  width: 100%;
  padding: 0;
  box-sizing: border-box;
`;

const Label = styled.label<{ hasCredits: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  line-height: 1.69;
  font-size: 0.89rem;
  font-weight: ${typography.weight.light};
  color: rgb(${(props) => props.theme.primaryTextColor});
  cursor: pointer;
  flex: 0 1 404px;
  margin: 0;
  width: 100%;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    display: inline-block;
    top: ${size.sm}px;
    left: 4px;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    border: ${(p) =>
      p.hasCredits
        ? `2px solid rgb(${ariesTheme.secondaryBorderColor})`
        : `2px solid rgb(${ariesTheme.colors.altoLight})`};
    background-color: rgb(${ariesTheme.white});
  }

  ${mediaQueries.forTabletHorizontalUp} {
    margin-left: 0;
    margin-right: 0;
  }
`;

const Input = styled.input`
  position: absolute;
  left: -9999999px;

  &:checked + label::before {
    border: 2px solid ${colors.green2};
  }

  &:focus + label::before {
    border: 2px solid rgba(${(props) => props.theme.black}, 30%);
    box-shadow: 0 0 0 2px
      rgb(${(props) => props.theme.primarySuccessColor}, 50%);
  }

  &:checked + label::after {
    content: '';
    position: absolute;
    display: inline-block;
    left: 10px;
    top: 18px;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    border-width: 1px;
    background-color: rgb(${(props) => props.theme.colors.pineGreen});
  }
`;

const LabelInner = styled.div`
  display: flex;
  padding-left: 51px;
  align-items: flex-start;
  width: 100%;
`;

interface AuthorizedProp {
  isAuthorized: boolean;
}
const TextContent = styled.div<AuthorizedProp>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin-left: ${size.md}px;
  width: 100%;
  ${(props) => (props.isAuthorized ? `align-self: center` : ``)}
`;

const ProductName = styled.a`
  ${typography.buttonTextSmall};
  text-decoration: none;
`;

const ProductOriginalPrice = styled.div`
  ${typography.captionText}
`;

const AdditionalInfo = styled.div`
  position: absolute;
  bottom: -42px;
  left: 32px;
  font-size: 14.2px;
  line-height: 1.97;
  letter-spacing: 0.1px;
  font-weight: ${typography.weight.regular};
  color: rgb(${(props) => props.theme.colors.tundora});
`;

const unAuthStyles = css`
  ${LabelInner} {
    padding-left: 0;
  }

  ${Input} {
    &:checked + label::after {
      display: none;
    }
  }

  ${Label} {
    &::before {
      display: none;
    }
  }

  ${AdditionalInfo} {
    ${(mediaQuery) => mediaQuery.theme.forPhoneOnly} {
      left: 0;
    }
  }
`;

const Wrapper = styled.div<AuthorizedProp>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${size.xl1}px;
  width: 100%;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.xl2}px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  ${(props) =>
    !props.isAuthorized &&
    css`
      ${unAuthStyles}
    `}
`;

const Content = styled.div`
  width: 100%;
  margin-bottom: ${size.md}px;
  color: ${colors.gray4};
  font-weight: normal;
`;

export const ImageWrapper = styled.div`
  min-width: 64px;
  position: relative;
  padding-top: 6px;
`;

export const BoxImage = styled.img`
  max-width: ${size.xl4}px;
  max-height: 54px;
`;

export const CircleBackground = styled.div`
  height: 55px;
  width: 52px;
  background-color: ${colors.teal1};
  position: absolute;
  top: -${size.xs3}px;
  left: ${size.xs2}px;
  border-radius: 50%;
  z-index: -1;
`;

const Title = styled.div`
  width: 100%;
  font-weight: bold;
  margin: ${size.xs1}px 0 ${size.xs2}px 0;
  color: ${colors.gray4};
`;

const symptomsLeftSpace = parseInt(size.xs1) - 3;

const Link = styled(TertiaryLink)`
  color: ${colors.green5};
  font-size: ${size.md}px;
  width: 100%;
  background: ${colors.white};
  padding-left: ${symptomsLeftSpace + 12}px;

  &::before,
  svg {
    display: none;
  }
`;

const UL = styled.ul`
  list-style: none;
  padding-left: ${symptomsLeftSpace}px;
`;

const LI = styled.li<{ visible: boolean }>`
  ${typography.bodyTextSmall}
  color: ${colors.gray4};
  font-weight: normal;
  padding-left: ${size.sm}px;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  position: ${(props) => (props.visible ? 'relative' : 'absolute')};
  transition: opacity 0.5s ease;
  overflow: hidden;

  &::before {
    position: absolute;
    left: 0;
    top: ${size.sm}px;
    content: '';
    display: inline-block;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    background-color: ${colors.gray5};
    margin-right: ${size.xs1}px;
  }
`;

export {
  Container,
  InnerContainer,
  Wrapper,
  Label,
  LabelInner,
  TextContent,
  Input,
  ProductName,
  ProductOriginalPrice,
  AdditionalInfo,
  Content,
  Title,
  Link,
  UL,
  LI,
};
