import { Button, Col, Container, Row } from '@everlywell/leaves';
import ProductSelectForm from 'components/ProductSelectForm';
import SectionWrapper from 'components/SectionWrapper';
import React, { useContext, useState } from 'react';
import analytics from 'utils/analytics';
import { MEMBERSHIP_CARD } from 'utils/constants/dataTest';
import { logError, scrollToId } from 'utils/helpers';
import { TestPlanContext } from 'utils/hooks/useTestPlan/context';
import { TestPlanStatusIds } from 'utils/hooks/useTestPlan/types';
import { UserContext } from 'utils/hooks/useUserContext/context';
import { MembershipTypeOptions, Product } from 'utils/types';
import { ANALYTICS } from '../../../utils/constants/analytics';

import * as S from './styles';

export type TestsIncludedSectionProps = {
  product: Product;
  uid?: string;
  title?: string;
  headline?: string;
  isMember: boolean;
  description: string | undefined;
};

export const TestsIncludedSection: React.FC<TestsIncludedSectionProps> = ({
  uid,
  title,
  headline,
  product,
  isMember,
  description,
}: TestsIncludedSectionProps) => {
  const [_loading, _setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const {
    userData: { membershipType },
  } = useContext(UserContext);
  const { createTestPlanItem, setTestPlanActiveStatus } =
    useContext(TestPlanContext);

  const handleOptionChange = (option: string): void => {
    setSelectedValue(option);
  };

  const onAddTestPlan = async () => {
    const hash = `${
      membershipType == MembershipTypeOptions.current ? 'current' : 'control'
    }-membership-test-plan`;
    try {
      _setLoading(true);
      await createTestPlanItem(selectedValue);
      await analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          spree_variant_id: selectedValue,
          label: ANALYTICS.LABELS.TEST_PLAN.add.testSelection,
          isMember,
        },
      });
      setTestPlanActiveStatus(TestPlanStatusIds.upcoming);
      setSelectedValue('');
      scrollToId(hash);
    } catch (err) {
      logError((err as Error).message, {
        component: 'TestsIncludedSection',
        method: 'onAddTestPlan',
      });
    } finally {
      _setLoading(false);
    }
  };

  return (
    <SectionWrapper id={uid} data-testid={uid}>
      <Container>
        <Row>
          <Col>
            <S.SectionName id="test-included-title">{title}</S.SectionName>
            <S.SectionTitle id="test-included-headline">
              {headline}
            </S.SectionTitle>

            <S.SectionDescription id="test-included-description">
              {description}
            </S.SectionDescription>

            {/* Do not remove this block, it is used in VWO for experimental purposes */}
            {!isMember && (
              <S.SubHeadline id="test-included-subheadline"></S.SubHeadline>
            )}
          </Col>
        </Row>
      </Container>
      <S.ProductSelectWrapper>
        <ProductSelectForm
          product={product}
          isMember={isMember}
          onOptionChange={handleOptionChange}
          selectedValue={selectedValue}
        />
      </S.ProductSelectWrapper>
      {isMember ? (
        <Container>
          <Row center="xs">
            <Col xs={12} md={10}>
              <S.InnerContent>
                <Button
                  appearance="primary"
                  onClick={() => onAddTestPlan()}
                  data-testid={MEMBERSHIP_CARD.ORDER_TEST}
                  isDisabled={selectedValue === '' || _loading}
                >
                  Add to queue
                </Button>
              </S.InnerContent>
            </Col>
          </Row>
        </Container>
      ) : null}
    </SectionWrapper>
  );
};

export default TestsIncludedSection;
